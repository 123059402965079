import './App.css';
import React, { useState } from 'react';

import Footer from './components/Footer'
import Header from './components/Header'
import Content from './components/Content'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className='components'>

      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
