import '../styles/content.css'
import React, { useState, useEffect } from 'react';
export default function Content() {
    const [isFirstImageChecked, setIsFirstImageChecked] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsFirstImageChecked(true)
        }, 500)
    }, [])

    const redirectTo = () => {
        window.location.href='https://m.facebook.com/profile.php?id=100038147869037';
    }

    return (
        <main>
            <div className='wrapper'>


                <div className='firstPage'>



                    <div className='background-one'>
                        <div className='spacer layer1'> </div>
                        <div className='locationMsg'>
                            <p className='font-effect-anaglyph paragraph'>Cigs4Less</p>
                            <p className='font-effect-anaglyph business-info paragraph'>Serving you since 2002</p>
                            <button className="custom-btn btn-11 font-effect-anaglyph">Read More</button>
                            <div className="arrow animated bounce"></div>
                        </div>
                    </div>

                </div>



                <div className='about-page'>
                    <div className='spacer2 layer2'> </div>
                    <div className='about-background layer2'>

                        <div  >
                            <h1 className='about-label'>About Us</h1>
                        </div>





                        <div className='about-us-card'>

                            <div className='about-us-info'>

                                <div className='image-about'>
                                    <img className='tobacco-img' src={require('../images/tobacco_PNG25.png')} />
                                </div>

                                <p className='about-paragraph'>Cigarettes 4 Less been serving Martinrz and surrounding cities since 2002. We are the oldest Smoke Shop in bay area.
                                    We sell verities of Tobacco products, cigarettes, domestic and import.
                                    We do sell Premium cigar, Vapes, CBD, Lotto, Glass pipes, Kretom and wide verities of Genral merchandise.
                                    We are the oldest and friendly smoke shop in Martinrz where smokers always welcome.Your happiness is our priority.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>


                <div className='location-wrapper layer3'>
                    <h1 className='location-label'>Location</h1>
                    <div className='location-info'>
                        <h2 className=' '>Visit us on <a href="#">508 Center Ave Martinez, CA 94553</a> </h2>







                        <div className="section full-height over-hide px-4 px-sm-0">
                            <div className="container">
                                <div className="row full-height justify-content-center">
                                    <div className="col-lg-10 col-xl-8 align-self-center padding-tb">
                                        <div className="section mx-auto text-center slider-height-padding">
                                            <input className="checkbox frst" type="radio" id="slide-1" name="slider" checked={isFirstImageChecked} onChange={() => setIsFirstImageChecked(true)} />
                                            <label for="slide-1"></label>
                                            <input onChange={() => setIsFirstImageChecked(false)} className="checkbox scnd" type="radio" name="slider" id="slider-2" />
                                            <label for="slider-2"></label>
                                            <input onChange={() => setIsFirstImageChecked(false)} className="checkbox thrd" type="radio" name="slider" id="slider-3" />
                                            <label for="slider-3"></label>
                                            <input onChange={() => setIsFirstImageChecked(false)} className="checkbox foth" type="radio" name="slider" id="slider-4" />
                                            <label for="slider-4"></label>
                                            <ul>
                                                <li>
                                                    <span>SHOP</span>
                                                </li>
                                                <li>
                                                    <span>LOREM</span>
                                                </li>
                                                <li>
                                                    <span>LOREM</span>
                                                </li>
                                                <li>
                                                    <span>LOREM</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>







                        <h5>Business Hours:</h5>
                        <br></br>
                        <img className='business-hours' src={require('../images/business_hours.jpg')}></img>
                    </div>
                </div>

                <div className='contact-us-wrapper layer4'>
                    <div >
                        <h1 className='contact-label'>Contact Us</h1>
                    </div>
                    <div className='phone'>
                        <h2 className='phone-h2'> Phone:  <a href="tel:+19252285317">+1-(925)-228-5317</a></h2>
                        <h2 className='email-h2'> Email: <a href="#">Cigs4Less4@yahoo.com</a></h2>
                    </div>
                    <div></div>
                    <h1 className='contact-label'> Social Media </h1>

                    <div onClick={()=> redirectTo()}  className="facebook button-big">
                        <i className="fa fa-facebook"> <a href='https://m.facebook.com/profile.php?id=100038147869037'></a></i>
                    </div>

                </div>
            </div>
        </main>
    )
}