import '../styles/footer.css'
export default function Footer() {
    return (
        // <footer>Footer Content — Header.com 2022</footer> 

        <footer className="site-footer">

            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">Copyright &copy; 2022 All Rights Reserved by
                            <a href="#">Cigs4Less</a>.
                        </p>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="social-icons">
                            <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>


                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}